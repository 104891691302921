import { Box, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { NavBarItemList } from "common/types/mobile-navbar";
import LazyImage from "components/LazyImage";
import { MainCategoryItem } from "modules/category/types";
import { FC, useMemo } from "react";

type Props = {
  // Omit icon prop type from MainCategoryItem to prevent ts error
  item?: NavBarItemList & Omit<MainCategoryItem, "icon">;
  key?: string;
  isMainCateg?: boolean;
  onCategoryClick?: (mainCateg: string) => void;
};

export const MainMenuDrawerItem: FC<Props> = ({
  item,
  key,
  isMainCateg = false,
  onCategoryClick,
}) => {
  const extraServiceBool = useMemo(
    () => item.title === "PC Worth Extra Service",
    [item.title]
  );

  const iconSRC = useMemo(
    () => (isMainCateg ? item.icon_sidedrawer : item.icon),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isMainCateg]
  );

  if (isMainCateg) {
    return item?.is_visible_sidedrawer ? (
      <ListItemButton key={key} onClick={() => onCategoryClick(item.name)}>
        <ListItemIcon>
          <LazyImage
            width={24}
            height={24}
            objectFit="contain"
            src={iconSRC || "/assets/images/sad_red_hair.png"}
            alt="kachi"
          />
        </ListItemIcon>
        <ListItemText
          sx={{
            opacity: 0.8,
          }}
        >
          {item.name}
        </ListItemText>
      </ListItemButton>
    ) : null;
  }

  return (
    <ListItemButton href={item.href} key={key} onClick={item.onClick}>
      {extraServiceBool ? (
        <Box mr={3.5}>
          <LazyImage
            sx={{
              width: "100%",
              borderRadius: "8.8px",
              border: "2px solid red",
            }}
            width={28}
            height={28}
            alt={`/assets/images/extra-service/pc-worth-extra-service-seal.png`}
            loading="eager"
            objectFit="contain"
            src={"/assets/images/extra-service/pc-worth-extra-service-seal.png"}
          />
        </Box>
      ) : (
        <ListItemIcon>
          <item.icon />
        </ListItemIcon>
      )}

      <ListItemText
        sx={{
          opacity: 0.8,
          color: extraServiceBool ? "#CF151A" : "",
        }}
      >
        {item.title}
      </ListItemText>
    </ListItemButton>
  );
};
