import SmartToyOutlinedIcon from "@mui/icons-material/SmartToyOutlined";
import DesktopWindowsOutlinedIcon from "@mui/icons-material/DesktopWindowsOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import { Badge } from "@mui/material";
import { colors } from "common";
import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import ListAltIcon from "@mui/icons-material/ListAlt";
import { useCart } from "hooks/use-cart";
import useWindowSize from "hooks/useWindowSize";
import { FC, useEffect, useState } from "react";
import { iconStyle, StyledNavLink, Wrapper } from "./styles";
import { useLoginStatus } from "hooks/useLoginStatus";
import { usePcwUserCart } from "hooks/use-pcw-cart";
import { navigationRoutes } from "common/constants/navigation-routes";
import { NavBarItemList } from "common/types/mobile-navbar";
import { makeStyles } from "@mui/styles";
import React from "react";
import { useOpacity } from "hooks/useOpacity";
import { Tiny } from "components/Typography";
import useWindowSizeV2 from "hooks/use-window-size-v2";
import PowerBuildsPrimary from "components/icons/PowerBuildsPrimary";

type Props = {
  handleOpenThisDrawer?: (d: string) => void;
};

const useStyles = makeStyles({
  menuOption: {
    flex: "1 1 0",
    display: "flex",
    fontSize: "13px",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    color: colors.primary,
    position: "relative",
  },
});

const MobileNavigationBar: FC<Props> = ({ handleOpenThisDrawer }) => {
  const width = useWindowSize();
  const classes = useStyles();
  const { cartLength } = useCart();
  const { pcwUserCartLength } = usePcwUserCart();
  const {
    opacity,
    isScrolledUp,
    setOpacity,
    lastScrollY,
    scrolling,
    setScrolling,
    handleScroll,
  } = useOpacity();

  const [branchOpen, toggleBranchOpen] = useState(false);
  const { isMobile } = useWindowSizeV2();

  const { isUserLogin } = useLoginStatus();
  const isLogin = isUserLogin;

  const list: NavBarItemList[] = [
    {
      title: "Pwrd Builds",
      icon: PowerBuildsPrimary,
      href: navigationRoutes.powered_builds,
    },
    {
      title: "AI builder",
      icon: SmartToyOutlinedIcon,
      href: navigationRoutes.aiBuilder,
    },
    {
      title: "PC Builder",
      icon: DesktopWindowsOutlinedIcon,
      href: navigationRoutes.diyBuilder,
    },
    {
      title: "PC Gallery",
      icon: CollectionsOutlinedIcon,
      href: navigationRoutes.buildGallery,
    },
    {
      title: "Pricelist",
      icon: ListAltIcon,
      href: navigationRoutes.pricelist,
    },
  ];

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    const timer = setTimeout(() => {
      if (scrolling) {
        setScrolling(false);
        setOpacity(1);
      }
    }, 500);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleScroll, lastScrollY, scrolling]);

  // if (branchMenuFlag) {
  //   return width <= 900 ? (
  //     <Wrapper
  //       sx={{
  //         opacity: opacity,
  //         transition: "opacity 0.4s ease, height 0.4s ease",
  //         zIndex: 10,
  //         height: branchOpen ? "40vh" : "15vh",
  //         pointerEvents: scrolling ? "none" : "auto",
  //       }}
  //     >
  //       <Grid container columns={1}>
  //         <Grid item xs={1} height={!branchOpen ? "50%" : "75%"}>
  //           <MobileBranchMenu
  //             branchOpen={branchOpen}
  //             toggleBranchOpen={toggleBranchOpen}
  //           />
  //         </Grid>
  //         <Grid item xs={1} height={"55px"}>
  //           <Grid
  //             container
  //             columns={25}
  //             justifyContent="center"
  //             alignItems="center"
  //             height={"100%"}
  //           >
  //             {list.map((item) =>
  //               item ? (
  //                 <Grid item key={item.title} xs={5}>
  //                   <StyledNavLink
  //                     href={item?.href || ""}
  //                     sx={{
  //                       color: colors.primary,
  //                       "&:hover": {
  //                         color: `${colors.primary} !important`, // retain color when pressed
  //                       },
  //                     }}
  //                     onClick={item?.onClick}
  //                   >
  //                     {item.title === "Cart" ? (
  //                       <Badge
  //                         badgeContent={
  //                           isLogin ? pcwUserCartLength : cartLength
  //                         }
  //                         color="primary"
  //                       >
  //                         <item.icon fontSize="small" sx={iconStyle} />
  //                       </Badge>
  //                     ) : (
  //                       <item.icon
  //                         color="secondary"
  //                         sx={iconStyle}
  //                         fontSize="small"
  //                       />
  //                     )}
  //                     {item.title}
  //                   </StyledNavLink>
  //                 </Grid>
  //               ) : null
  //             )}
  //             <Grid item xs={5}>
  //               <a
  //                 href={undefined}
  //                 onClick={() => handleOpenThisDrawer("menu")}
  //                 className={classes.menuOption}
  //               >
  //                 <MenuIcon sx={iconStyle} fontSize="small" />
  //                 Menu
  //               </a>
  //             </Grid>
  //           </Grid>
  //         </Grid>
  //       </Grid>
  //     </Wrapper>
  //   ) : null;
  // }

  return width <= 900 ? (
    <Wrapper
      sx={{
        opacity: 1,
        transition: "transform 0.5s ease",
        transform: isMobile
          ? lastScrollY === 0 || isScrolledUp
            ? "translateY(0)"
            : "translateY(100%)"
          : "translateY(0)",
      }}
    >
      {list.map((item) =>
        item ? (
          <StyledNavLink
            href={item?.href || ""}
            sx={{ color: colors.primary }}
            key={item.title}
            onClick={item?.onClick}
          >
            {item.title === "Cart" ? (
              <Badge
                badgeContent={isLogin ? pcwUserCartLength : cartLength}
                color="primary"
              >
                <item.icon fontSize="small" sx={iconStyle} />
              </Badge>
            ) : (
              <item.icon color="secondary" sx={iconStyle} fontSize="small" />
            )}
            <Tiny>{item.title}</Tiny>
          </StyledNavLink>
        ) : null
      )}
      <StyledNavLink
        sx={{ color: colors.primary, ml: -1.5 }}
        onClick={() => handleOpenThisDrawer("menu")}
        href={""}
      >
        <MenuIcon color="secondary" sx={iconStyle} fontSize="small" />
        <Tiny color="#0c304a">Menu</Tiny>
      </StyledNavLink>
    </Wrapper>
  ) : null;
};

export default MobileNavigationBar;
